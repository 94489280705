<template>
  <div class="app-container park-electric-situation">
    <el-row :gutter="10">
      <el-col :span="4">
        <el-select
          style="width: 100%"
          placeholder="选择项目"
          v-model="searchForm.projectName"
          @change="projectChange"
        >
          <el-option
            v-for="item in projectOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          style="width: 100%"
          placeholder="选择楼栋"
          v-model="searchForm.build"
          @change="getFloorList"
          filterable
          clearable
        >
          <el-option
            v-for="item in buildingOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          style="width: 100%"
          placeholder="选择楼层"
          v-model="searchForm.floor"
          @change="getRoomList"
          filterable
          clearable
        >
          <el-option
            v-for="item in floorOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          style="width: 100%"
          placeholder="选择房间"
          v-model="searchForm.room"
          @change="roomChange"
          filterable
          clearable
        >
          <el-option
            v-for="item in roomOptions"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          style="width: 100%"
          v-model="selectData.deviceNames"
          multiple
          collapse-tags
          reserve-keyword
          placeholder="选择设备"
          :remote-method="remoteMethod"
          remote
          filterable
        >
          <el-checkbox
            style="padding-left: 20px; margin: 10px 0"
            v-model="deviceCheckedAll"
            @change="selectAll"
            >全选</el-checkbox
          >
          <el-option
            v-for="item in deviceNameOptions"
            :key="item.productKey + item.deviceName"
            :label="item.deviceName"
            :value="item.deviceName"
          >
            <span style="float: left">{{ item.deviceName }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              item.deviceDescription
            }}</span>
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin-top: 10px">
      <el-col :span="8">
        <el-date-picker
          style="width: 100%"
          v-model="selectData.date"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="8">
        <el-button type="primary" @click="getTableList"> 查询 </el-button>
        <el-button
          type="primary"
          @click="exportHandler"
          v-loading="exportLoading"
        >
          导出
        </el-button>
      </el-col>
    </el-row>
    <div class="table-container">
      <el-table
        :data="tableData"
        height="100%"
        border
        stripe
        :header-cell-style="
          () => {
            return 'background-color: #F0F4FA; color: #5D687C; ';
          }
        "
        v-loading="tableLoading"
      >
        <el-table-column
          fixed
          align="center"
          type="index"
          width="50"
        ></el-table-column>
        <el-table-column
          fixed
          align="center"
          prop="deviceName"
          label="表号"
          width="150"
        >
          <template slot-scope="scope">
            <span class="link-span" @click="jumpToDeviceShadow(scope.row)">
              {{ scope.row.deviceName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="build" label="楼栋" />
        <el-table-column align="center" prop="floor" label="楼层" />
        <el-table-column align="center" prop="room" label="房间" />
        <el-table-column
          align="center"
          prop="deviceDescription"
          label="位置描述"
        />
        <el-table-column align="center" prop="lastReadValue" label="上次读数" />
        <el-table-column align="center" prop="readValue" label="本次读数" />
        <el-table-column align="center" prop="ratio" label="倍率" />
        <el-table-column align="center" prop="used" label="用量" />
        <el-table-column align="center" prop="calendarDate" label="日期" />
      </el-table>
    </div>
    <div class="pagination-container">
      <pagination
        layout="sizes,total , prev, pager, next, jumper"
        :background="false"
        :total="total"
        :page.sync="searchForm.current"
        :limit.sync="searchForm.rowCount"
        @pagination="paginationChange"
      />
    </div>
  </div>
</template>

<script>
import {
  getBuildingListByProjectName,
  getFloorListByBuild,
  getRoomListByFloor,
  getElectricityCondition,
  getWaterElectricDeviceList,
  electricityConditionExport,
} from "@/api/ruge/bmsNew/electricityMeter.js";
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  data() {
    return {
      exportLoading: false,
      deviceCheckedAll: false,
      tableLoading: false,
      tableData: [],
      deviceNameOptions: [],
      resourceOptions: [],
      projectOptions: [
        {
          value: "锦绣一期",
          label: "锦绣一期",
        },
        {
          value: "锦绣二期",
          label: "锦绣二期",
        },
        {
          value: "锦绣三期",
          label: "锦绣三期",
        },
      ],
      buildingOptions: [],
      floorOptions: [],
      roomOptions: [],
      selectData: {
        date: [],
        deviceNames: [],
      },
      searchForm: {
        projectName: "锦绣一期",
        build: "",
        floor: "",
        room: "",
        current: 1,
        rowCount: 10,
      },
      total: 0,
    };
  },
  created() {
    this.getDeviceList();
    this.getBuildingList("锦绣一期");
  },
  methods: {
    jumpToDeviceShadow(row) {
      const url = `${location.origin}${location.pathname}#/iot/device/detail?productKey=${row.productKey}&deviceName=${row.deviceName}&activeTabName=shadow`;
      window.open(url, "_blank");
    },
    async exportHandler() {
      this.exportLoading = true;
      const chooseDate = this.selectData.date || [];
      electricityConditionExport({
        ...this.searchForm,
        ...{
          startDate: chooseDate[0] ? new Date(chooseDate[0]).getTime() : null,
          endDate: chooseDate[1] ? new Date(chooseDate[1]).getTime() : null,
          deviceNames: this.selectData.deviceNames.join(","),
        },
      })
        .then((taskId) => {
          this.$message({
            type: "success",
            message: this.$t("message.operationSuccess"),
          });
          let exportObj = {
            taskId,
            taskName: "园区租户用电情况",
            taskStatus: 0,
            rootPath: "istation",
          };
          this.$store.dispatch("PushExportNotice", exportObj);
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
    selectAll() {
      this.selectData.deviceNames = [];
      if (this.deviceCheckedAll) {
        this.deviceNameOptions.forEach((item) => {
          this.selectData.deviceNames.push(item.deviceName);
        });
      } else {
        this.selectData.deviceNames = [];
      }
      this.$emit("update:updateMultipleSelect", this.selectData.deviceNames);
    },
    paginationChange(current) {
      this.searchForm.current = current.page;
      this.getTableList();
    },
    getTableList() {
      this.tableLoading = true;
      const chooseDate = this.selectData.date || [];
      getElectricityCondition({
        ...this.searchForm,
        ...{
          startDate: chooseDate[0] ? new Date(chooseDate[0]).getTime() : null,
          endDate: chooseDate[1] ? new Date(chooseDate[1]).getTime() : null,
          deviceNames: this.selectData.deviceNames.join(","),
        },
      })
        .then((res) => {
          this.tableData = res.rows;
          this.total = res.total;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    getRoomList(floor) {
      if (floor) {
        getRoomListByFloor({ floor, deviceType: "电表" }).then((res) => {
          this.roomOptions = res.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        });
      } else {
        this.roomOptions = [];
      }
      this.searchForm.room = "";
      this.clearDeviceDatas();
    },
    roomChange() {
      this.clearDeviceDatas();
    },
    getFloorList(build) {
      if (build) {
        getFloorListByBuild({ build, deviceType: "电表" }).then((res) => {
          this.floorOptions = res.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        });
      } else {
        this.floorOptions = [];
        this.roomOptions = [];
      }
      this.searchForm.room = "";
      this.searchForm.floor = "";
      this.clearDeviceDatas();
    },
    projectChange(projectName) {
      this.searchForm.build = "";
      this.searchForm.floor = "";
      this.searchForm.room = "";
      this.buildingOptions = [];
      this.floorOptions = [];
      this.roomOptions = [];
      this.getBuildingList(projectName);
      this.clearDeviceDatas();
    },
    clearDeviceDatas() {
      this.selectData.deviceNames = [];
      this.deviceCheckedAll = false;
      this.getDeviceList();
    },
    getBuildingList(projectName) {
      getBuildingListByProjectName({ projectName, deviceType: "电表" }).then(
        (res) => {
          this.buildingOptions = res.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        }
      );
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.getDeviceList(query);
      } else {
        this.deviceNameOptions = this.resourceOptions;
      }
    },
    getDeviceList(filter) {
      getWaterElectricDeviceList({
        projectName: this.searchForm.projectName,
        build: this.searchForm.build,
        floor: this.searchForm.floor,
        room: this.searchForm.room,
        deviceName: filter,
        deviceType: "电表",
      }).then((res) => {
        if (!this.resourceOptions.length) {
          this.resourceOptions = res;
        }
        this.deviceNameOptions = res;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.park-electric-situation {
  .table-container {
    margin-top: 20px;
    height: calc(100% - 170px);
  }
  .pagination-container {
    margin-left: -10px;
  }
  ::v-deep(.el-table th.el-table__cell > .cell) {
    font-weight: bold;
  }
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .link-span {
    color: #1a4cec;
    cursor: pointer;
  }
}
</style>